/* Firebase */
import { initializeApp } from 'firebase/app';
import {
  browserPopupRedirectResolver,
  initializeAuth,
  connectAuthEmulator,
  getAuth,
  onAuthStateChanged,
  indexedDBLocalPersistence,
} from 'firebase/auth';
import {
  connectFirestoreEmulator,
  doc,
  getDoc,
  getFirestore,
} from 'firebase/firestore';
import { useContext, useEffect, useState, createContext } from 'react';
import { useLocation } from 'react-router-dom';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,

  services: {
    auth: {
      persistence: 'local', // default
      initialize: {
        onAuthStateChangedMutation: 'auth/ON_AUTH_STATE_CHANGED_MUTATION',
        onAuthStateChangedAction: 'auth/onAuthStateChangedAction',
        subscribeManually: false,
      },
      ssr: false,
      emulatorPort: process.env.NODE_ENV === 'development' ? 9099 : undefined,
      emulatorHost: 'http://localhost',
    }, // Just as example. Can be any other service.
    firestore: {
      memoryOnly: false, // default
      //chunkName: process.env.NODE_ENV !== 'production' ? 'firebase-auth' : '[id]', // default
      enablePersistence: true,
      emulatorPort: process.env.NODE_ENV === 'development' ? 8080 : undefined,
      emulatorHost: 'localhost',
    },
    functions: {
      emulatorPort: 5001,
      emulatorHost: 'http://localhost',
    },
    storage: {
      emulatorPort: process.env.NODE_ENV === 'development' ? 9199 : undefined,
      emulatorHost: 'http://localhost',
    },
    database: true,
  },
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
initializeAuth(firebaseApp, {
  persistence: [indexedDBLocalPersistence], //, browserLocalPersistence, browserSessionPersistence,
  popupRedirectResolver: browserPopupRedirectResolver,
});

if (process.env.REACT_APP_SIMULATOR === 'true') {
  connectAuthEmulator(getAuth(), 'http://localhost:9099');
  connectFirestoreEmulator(getFirestore(), 'localhost', 8080);
  const functions = getFunctions(firebaseApp);
  connectFunctionsEmulator(functions, 'localhost', 5001);
  const storage = getStorage();
  connectStorageEmulator(storage, 'localhost', 9199);
}

export const AuthContext = createContext();

export const AuthContextProvider = (props) => {
  const [user, setUser] = useState();
  const [ready, setReady] = useState(false);
  const [error, setError] = useState();
  const location = useLocation();

  const onUser = async (data) => {
    if (data) {
      const docRef = doc(getFirestore(), 'users', data.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setUser({ ...data, doc: docSnap.data() });
      } else {
        setUser(null);
      }
    } else {
      setUser(null);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), onUser, setError);
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user !== undefined) {
      setReady(true);
    }
  }, [user]);

  return (
    <AuthContext.Provider value={{ user, error, location, ready }} {...props} />
  );
};

export const useAuthState = () => {
  const auth = useContext(AuthContext);
  return { ...auth, isAuthenticated: auth && auth.user != null };
};
