import {
  useContext,
  useEffect,
  createContext,
  useReducer,
  useMemo,
  useState,
} from 'react';
import reducer from './reducer';
import { useAuthState } from './firebase';
import {
  collection,
  getFirestore,
  where,
  query,
  orderBy,
  limit,
  getDocs,
  startAfter,
  doc,
  getDoc,
} from 'firebase/firestore';

export const AppContext = createContext();

export const AppContextProvider = (props) => {
  const PAGE_ITEMS = 5;
  const { user } = useAuthState();

  const initialState = {
    faqs: [],
    initialized: false,
    risk: null,
    risks: [],
    evaluations: [],
    resolutions: [],
    positions: [],
    paginationArchiveStatus: '',
    paginationActiveStatus: '',
    paginationArchive: 0,
    paginationActive: 0,
    searchResolution: null,
    archive: false,
    activeList: [],
    archivedList: [],
    entreprises: [],
    poles: [],
    entreprise: null,
    pole: null,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const [lastArchivedDoc, setLastArchivedDoc] = useState('');
  const [lastActiveDoc, setLastActiveDoc] = useState('');

  const [lastActivePage, setLastActivePage] = useState(-1);
  const [lastArchivePage, setLastArchivePage] = useState(-1);

  useEffect(() => {
    if (user) {
      const loadUserEnterprise = async () => {
        let docRef = doc(
          getFirestore(),
          `/entreprises/${user.doc.entreprise}/`
        );
        let docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          dispatch({
            type: 'entreprise',
            payload: data,
          });
        }
      };

      if (user) {
        loadUserEnterprise();
      }
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const path =
        process.env.NODE_ENV === 'development'
          ? 'http://localhost:9199/whergo-staging.appspot.com'
          : process.env.REACT_APP_FIREBASE_STORAGE;

      const list = [];

      const loadFAQs = async () => {
        try {
          const raw = await fetch(
            `${path}/public/faq/index.json?v=${Date.now()}`
          );
          const response = await raw.json();
          if (!response.error) {
            return response;
          } else {
            return [];
          }
        } catch (e) {
          return [];
        }
      };

      const loadEntrepriseFAQs = async () => {
        try {
          const raw = await fetch(
            `${path}/public/faq/${user.doc.entreprise}.json?v=${Date.now()}`
          );
          const response = await raw.json();
          if (!response.error) {
            return response;
          } else {
            return [];
          }
        } catch (e) {
          return [];
        }
      };

      const load = async () => {
        const faqs = await loadFAQs();
        const entrepriseFAQs = await loadEntrepriseFAQs();
        dispatch({
          type: 'faqs',
          payload: [...entrepriseFAQs, ...faqs],
        });
      };
      load();
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const loadUserData = async () => {
        let docRef = doc(
          getFirestore(),
          `/entreprises/${user.doc.entreprise}/poles/${user.doc.pole}`
        );
        let docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          dispatch({
            type: 'pole',
            payload: data.name,
          });
        }
      };

      if (user) {
        if (user.doc.role === 'pole') {
          loadUserData();
        }
      }
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const lastPage = state.archive
        ? state.paginationArchive
        : state.paginationActive;
      const currentPage = state.archive ? lastArchivePage : lastActivePage;

      const load = async () => {
        const ref = collection(
          getFirestore(),
          `/entreprises/${user.doc.entreprise}/poles/${user.doc.pole}/fiches/`
        );
        let filters = [];
        let last = state.archive ? lastArchivedDoc : lastActiveDoc;
        if (currentPage === -1) {
          last = '';
        }
        let userFilter = where('author', '==', user.uid);

        if (user.doc.role === 'pole') {
          userFilter = null;
        }

        if (state.searchResolution && state.searchResolution.length) {
          filters = [
            where('archive', '==', state.archive),
            where('deleted', '==', false),
            where('name', '>=', state.searchResolution),
            limit(PAGE_ITEMS),
          ];
        } else {
          filters = [
            where('archive', '==', state.archive),
            where('deleted', '==', false),
            orderBy('createdAt', 'desc'),
            limit(PAGE_ITEMS),
            startAfter(last),
          ];
        }

        if (userFilter) {
          filters.push(userFilter);
          //console.log('ROLE',user.doc.role,userFilter)
        }

        const q = query(ref, ...filters);

        const documents = await getDocs(q);
        const list = [];

        documents.forEach((doc) =>
          list.push({
            ...doc.data(),
            id: doc.id,
          })
        );

        dispatch({
          type: 'add',
          payload: { items: list },
        });

        if (
          documents.docs.length &&
          (!state.searchResolution || !state.searchResolution.length)
        ) {
          dispatch({
            type: 'paginationStatus',
            payload: { value: 'more' },
          });
        } else {
          dispatch({
            type: 'paginationStatus',
            payload: { value: 'empty' },
          });
        }

        if (state.archive) {
          setLastArchivedDoc(documents.docs[documents.docs.length - 1]);
        } else {
          setLastActiveDoc(documents.docs[documents.docs.length - 1]);
        }

        if (state.archive) {
          setLastArchivePage(state.paginationArchive);
        } else {
          setLastActivePage(state.paginationActive);
        }
      };

      if (lastPage !== currentPage || currentPage === -1) {
        load();
      } else {
        console.log('list debug SAME PAGE');
      }
    } else {
      setLastArchivedDoc('');
      setLastActiveDoc('');
      setLastActivePage(-1);
      setLastArchivePage(-1);
      dispatch({
        type: 'logout',
      });
    }
  }, [
    user,
    state.paginationArchive,
    state.paginationActive,
    state.searchResolution,
    state.archive,
    lastArchivePage,
    lastActivePage,
  ]);

  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return <AppContext.Provider value={{ ...contextValue }} {...props} />;
};

export const useAppState = () => {
  const app = useContext(AppContext);
  return { ...app };
};
