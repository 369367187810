import { useController, UseControllerProps } from 'react-hook-form';
import { IonInput, IonLabel, IonItem } from '@ionic/react';
import { TextFieldTypes } from '@ionic/core';

type InputProps = {
  readonly?: boolean;
  disabled?: boolean;
  label: string;
  defaultValue?: string;
  placeholder?: string | undefined;
  type: TextFieldTypes | undefined;
} & UseControllerProps<any>;

export const Input = (props: InputProps) => {
  const {
    field: { onChange, onBlur, value, name, ref },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController(props);
  return (
    <IonItem className={'bg-white'}>
      <IonLabel position={'stacked'}>{props.label}</IonLabel>
      <IonInput
        readonly={props.readonly}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
        value={value}
        type={props.type}
        onIonBlur={onBlur} // notify when input is touched
        onIonChange={onChange} // send value to hook form
        ref={ref}
      />
      {error && (
        <span className={'text-red-500 text-14'}>champ obligatoire</span>
      )}
    </IonItem>
  );
};

//
/*
 <p>{isTouched && "Touched"}</p>
            <p>{isDirty && "Dirty"}</p>
            <p>{invalid ? "invalid" : "valid"}</p>
<input className={"border border-secondary"} {...field} placeholder={props.name} />
<Controller
                                control={control}
                                name="password"
                                rules={{required: true}}
                                render={({
                                             field: {onChange, onBlur, value, name, ref},
                                             fieldState: {invalid, isTouched, isDirty, error},
                                             formState,
                                         }) => (
                                    <IonInput
                                        onIonBlur={onBlur} // notify when input is touched
                                        onIonChange={onChange} // send value to hook form
                                        ref={ref}

                                    />
                                )}
                            />
 */
